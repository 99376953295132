import Page from "components/Page";
import {
  SUPPLIER_FETCH_INVENTORY,
  CREATE_SUPPLIER_STOCK_UPDATE_REQUEST,
} from "./graphql";
import { useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "AppContainer";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { parseError } from "Apollo";
import Input from "components/Input";
import moment from "moment";
import Button from "components/Button";
import { toast } from "react-toastify";
import SearchBar from "components/SearchBar";

const RequestUpdate = () => {
  const { user } = useContext(AppContext);
  const { loading, error, data } = useQuery(SUPPLIER_FETCH_INVENTORY, {
    variables: { supplierId: user.supplier.id },
  });
  const [searchText, setSearchText] = useState("");

  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [submit, submitRes] = useMutation(
    CREATE_SUPPLIER_STOCK_UPDATE_REQUEST,
    {
      onCompleted() {
        toast.success(
          "Inventory update request submitted successfully. We will review and update the inventory number accordingly.",
        );
      },
      onError(error) {
        toast.error(parseError(error));
      },
    },
  );

  useEffect(() => {
    if (data)
      setUpdatedProducts(
        data.products.results.map((i) => ({
          ...i,
          qty: i.latestSupplierStock.latestQty,
          readyDate: moment(i.latestSupplierStock.readyDate).format(
            "YYYY-MM-DD",
          ),
          remark: i.latestSupplierStock.remark || "",
        })),
      );
  }, [data]);

  function submitHandler() {
    const products = updatedProducts
      .filter((i) => {
        return (
          i.latestSupplierStock.latestQty !== parseInt(i.qty) ||
          moment(i.latestSupplierStock.readyDate).format("YYYY-MM-DD") !==
            moment(i.readyDate).format("YYYY-MM-DD")
        );
      })
      .filter((i) => ({ ...i, originalQty: i.latestSupplierStock.latestQty }));
    const variables = {
      supplierId: user.supplier.id,
      data: JSON.stringify({ products }),
    };
    submit({ variables });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors errors={parseError(error)} />;

  return (
    <Page
      title="Request Inventory Update"
      backTo="./.."
      topbarRightView={
        <Button loading={submitRes.loading} onClick={submitHandler}>
          Submit
        </Button>
      }
    >
      <div className="mx-8 my-4 opacity-70 text-sm">
        <div>
          You can enter the latest inventory quantity below. Please make sure
          the inventory quantity entered is the remaining available quantity
          except for all PO quantities.
          <br />
          您可以在下面输入最新的库存数量，请确保录入的库存数量是截止到目前除了所有PO数量以外的剩余可用数量。只有被更新的产品数据才会被提交。
        </div>
      </div>

      <div className="card m-8 mt-0 flex-1 flex flex-col overflow-auto p-0 rounded-xl">
        <div className="relative text-sm pb-4">
          <table>
            <thead>
              <tr className=" whitespace-nowrap">
                <th className="px-6 py-4 w-full">
                  <div className="flex items-center space-x-3">
                    <div>Product</div>
                    <div className="w-full">
                      <SearchBar
                        placeholder="可使用item number或者名称进行搜索."
                        value={searchText}
                        onChange={setSearchText}
                      />
                    </div>
                  </div>
                </th>
                <th className="px-6 py-4 text-right">Currenty QTY</th>
                <th className="px-6 py-4 text-right">New QTY</th>
                <th className="px-6 py-4 text-right">Ready Date</th>
                <th className="px-6 py-4 text-right">Remark / 备注</th>
              </tr>
            </thead>
            <tbody>
              {updatedProducts
                .filter((i) => {
                  return (
                    i.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    i.number.toLowerCase().includes(searchText.toLowerCase())
                  );
                })
                .map((i) => (
                  <tr
                    key={i.id}
                    className={
                      i.latestSupplierStock.latestQty === parseInt(i.qty) &&
                      moment(i.latestSupplierStock.readyDate).format(
                        "YYYY-MM-DD",
                      ) === moment(i.readyDate).format("YYYY-MM-DD")
                        ? ""
                        : "bg-green-300 dark:bg-green-900"
                    }
                  >
                    <td className="px-6">
                      [{i.number}] {i.name}
                    </td>
                    <td className="px-6 text-right">
                      {i.latestSupplierStock.latestQty}
                    </td>
                    <td className="px-6 text-right">
                      <Input
                        className="text-right"
                        style={{
                          width: "100px",
                        }}
                        value={i.qty}
                        onChange={(e) => {
                          const newProducts = updatedProducts.map((p) =>
                            p.id === i.id ? { ...p, qty: e.target.value } : p,
                          );
                          setUpdatedProducts(newProducts);
                        }}
                      />
                    </td>
                    <td className="px-6">
                      <div className="flex justify-end z-50">
                        <input
                          className="px-2 py-1 rounded-xl placeholder-gray-300  bg-gray-100 bg-opacity-80 dark:bg-gray-900 dark:text-gray-200 disabled:cursor-not-allowed"
                          type="date"
                          value={i.readyDate}
                          onChange={(e) => {
                            const newProducts = updatedProducts.map((p) =>
                              p.id === i.id
                                ? {
                                    ...p,
                                    readyDate: moment(e.target.value).format(
                                      "YYYY-MM-DD",
                                    ),
                                  }
                                : p,
                            );
                            setUpdatedProducts(newProducts);
                          }}
                        />
                      </div>
                    </td>
                    <td className="px-6">
                      <Input
                        className="text-right"
                        // style={{ width: "100px" }}
                        value={i.remark}
                        onChange={(e) => {
                          const newProducts = updatedProducts.map((p) =>
                            p.id === i.id
                              ? { ...p, remark: e.target.value }
                              : p,
                          );
                          setUpdatedProducts(newProducts);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default RequestUpdate;
